.mobile_navbar {
  z-index: 20;
}
.burger {
  z-index: 20;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 20px;
  right: 15px;
  width: 30px;
  height: 30px;
}
.burger img {
  width: 70%;
  animation: enter 500ms;
}
.menu a {
  margin-bottom: 10px;
  font-size: 1.3rem;
}

@keyframes animate {
  0% {
    width: 0vw;
    opacity: 0;
  }
  100% {
    opacity: 1;
    width: 65vw;
  }
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
