@media all and (max-width: 760px) {
  /* NAVBAR */
  .desktop_navbar {
    display: none;
  }
  .mobile_navbar {
    display: block;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
  }
  .menu {
    display: block;
    background-color: black;
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    width: 65vw;
    height: 100vh;
    animation: animate 500ms;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5%;
    box-sizing: border-box;
  }

  /* HOME */
  .welcome_div {
    height: 100vh;
  }
  .welcome_img {
    height: 100vh;
  }
  .top_banner,
  .medium_banner_img2 {
    display: none;
  }
  .icon_card {
    margin-bottom: 50px;
  }
  .contacto form {
    max-width: 100vw;
    width: 100vw;
    min-width: 1px;
    box-sizing: border-box;
    padding: 3rem 2rem 5rem;
  }
  .contacto input {
    width: 98%;
    margin: auto 0;
  }
  .contacto form textarea {
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
  }

  /* NOSOTROS */
  .nosotros {
    padding: 1rem 1rem;
  }
  .nosotros h1 {
    padding: 0;
    text-align: center;
  }
  .nosotros_grid2 {
    display: block;
  }
  .nosotros_grid2 > div {
    width: 100%;
    padding: 2rem 0;
    line-height: 1.8rem;
    font-size: 20px;
  }

  .us_img_top {
    display: none;
  }

  /* SERVICES */
  .services {
    padding: 4rem 1rem;
  }
  .services_section {
    padding: 2rem 0rem;
  }
  .services_section p {
    text-align: center;
    width: 90%;
  }
  .accordion {
    border: 1px solid black;
    width: 100%;
    margin: 0 auto;
    background-color: var(--shade-1);
  }

  /* TRABAJOS */
  .trabajos p {
    width: 80%;
  }
  .trabajos iframe {
    width: 90%;
  }

  /* CONTACTE */
  .contacto_page {
    padding-top: 3rem;
  }
  .contacto_section {
    padding: 0;
  }
  .contacto_page form {
    background-color: transparent;
    padding: 0rem 2rem 5rem 2rem;
  }
  .contacto_page input {
    width: 100%;
  }
  .contacto_page form textarea {
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
  }
  .whatsapp_fixed {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 200;
    width: 20vw;
  }

  /* EXTRA */
  .whatsapp_fixed img {
    width: 100%;
  }
}
