/* =================== */

/* GENERAL */
* {
	padding: 0;
	margin: 0;
	font-family: "Roboto Mono", Tahoma, sans-serif;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
html {
	background: black;
	max-width: 100vw !important;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	color: white;
}
.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.flexColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* COLOR PALETTE */
:root {
	--shade-1: hsla(261, 100%, 83%, 0.671);
	--shade-2: hsl(261, 80%, 67%);
	--shade-3: hsl(261, 61%, 52%);
	--shade-4: hsl(261, 41%, 36%);
	--shade-5: hsl(261, 22%, 21%);
	--shade-6: hsl(261, 3%, 6%);
}

/* NAVBAR */
.desktop_navbar {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-content: center;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: var(--shade-2);
	height: 60px;
	padding: 5px 0;
	box-sizing: border-box;
	z-index: 10;
}
.navbar_left {
	padding-left: 2vw;
}
.navbar_left .header_logo {
	/* border-radius: 100%;
  border: 1px solid black;
  box-shadow: 2px 2px 2px #000000 !important;
  width: 50px !important;
  height: 50px !important; */
	display: flex;
	justify-content: left;
	align-items: center;
}
.header_logo h3 {
	font-size: 1.5rem;
	text-shadow: 6px 3px 3px rgba(150, 150, 150, 1);
}
.navbar_right {
	display: flex;
	justify-self: right;
	align-items: center;
	padding-right: 3vw;
}
.navbar_right a {
	text-decoration: none;
	color: white;
	margin-right: 15px;
}
.mobile_navbar {
	display: none;
}

.active {
	color: var(--shade-6) !important;
}
.active2 {
	color: var(--shade-3) !important;
}
.navbar_right a:hover {
	color: var(--shade-6);
	transition: all linear 300ms;
}

/* HOME */

.welcome_div {
	position: relative;
	width: 100vw;
	height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.welcome_img {
	width: 100vw;
	height: calc(100vh - 60px);
	object-fit: cover;
	position: absolute;
}
.welcome_div h1,
.welcome_div h2 {
	margin-bottom: 2rem;
	position: relative;
	color: var(--shade-2);
	text-align: center;
}
.top_banner {
	margin-top: -4px;
	padding: 5rem;
	flex-direction: column;
	background-color: var(--shade-2);
	color: var(--shade-6);
}
.welcome_div h1 {
	font-size: 5rem;
}
.medium_banner {
	margin-top: -4px;
	padding: 5rem;
	background-color: var(--shade-2);
	color: var(--shade-6);
	justify-content: space-evenly;
}
.icon_card {
	min-width: 200px;
}
.medium_banner h3 {
	border-bottom: 1px solid white;
	text-align: center;
}
.medium_banner img {
	height: 100px;
	margin: 0 auto 20px;
	cursor: pointer;
}
.medium_banner a:hover {
	transition: all linear 1s;
	filter: invert(93%) sepia(33%) saturate(882%) hue-rotate(331deg) brightness(123%) contrast(88%);
}
.medium_banner_img {
	width: 100%;
	object-fit: cover;
}
.contacto {
	height: 90vh;
	padding: 7rem 0;
}
.contacto button {
	padding: 10px 20px;
	border-radius: 100px;
	outline: none;
}
.contacto form {
	padding: 3rem 2rem 5rem 5rem;
	background-color: hsla(261, 100%, 83%, 0.762);
	border-radius: 8px;
	min-width: 600px;
	width: 60%;
	margin: 0 auto;
}
.contacto form h4 {
	margin-bottom: 1rem;
	font-size: 1.5rem;
}
.contacto form label,
.contacto form input,
.contacto form textarea {
	display: block;
	margin-bottom: 0.5rem;
}
.contacto form textarea {
	padding: 0.5rem;
	width: 400px;
	height: 150px;
}
.message {
	margin-bottom: 10px;
}

/* NOSOTROS */
.nosotros {
	background: url(../../src/Resources/pics/_A6A9848.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	color: white;
	min-height: 100vh;
	padding: 3rem;
}
.nosotros h1 {
	padding-left: 4rem;
}
.nosotros_grid2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	max-width: 100vw;
	box-sizing: border-box;
}
.nosotrs_grid2 > div {
	width: 40vw;
}
.nosotros_grid2 div {
	padding: 2rem;
	box-sizing: border-box;
	text-align: justify;
	font-size: 1.2rem;
	line-height: 1.8rem;
}
.us_img_top > img,
.nosotros_carousel_container {
	width: 100%;
	cursor: pointer;
}
.nosotros_carousel_container {
	margin-top: 5rem;
	text-align: center;
}
.nosotros_carousel_container img {
	max-width: 100%;
}

/* SERVICIOS */
.services {
	/* foto helmet */
	background: url(../../src/Resources/pics/_A6A9804.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	color: white;
	min-height: 100vh;
	padding: 4rem 5rem;
	text-align: center;
}
.services h1 {
	font-size: 2.5rem;
	margin-bottom: 20px;
}
.services a {
	font-size: 1.5rem;
	color: white;
	margin-right: 1.5rem;
}
.services_section {
	padding: 2rem 5rem;
}
.services_section p {
	margin: 0 auto 3rem;
	width: 70%;
	text-align: justify;
	font-size: 1.3rem;
}
.services_section a {
	font: inherit;
	margin: 0;
	color: var(--shade-3);
}
.services_section a:hover {
	text-decoration: underline;
}
.services_table {
	margin: 0 auto 50px;
	text-align: left;
}
.services_table td {
	color: white;
	font-size: 1.3rem;
	min-width: 10vw;
	border: 1px solid white;
	padding: 1rem 2rem;
	background: var(--shade-1);
}

/* ACCORDION */
.accordion {
	border: 1px solid black;
	width: 80%;
	margin: 0 auto;
	background-color: var(--shade-1);
}
.accordion__button {
	background-color: var(--shade-2);
	border: 1px solid black;
	padding: 10px;
	cursor: pointer;
}

/* TRABAJOS */
.trabajos {
	background: url(../../src/Resources/pics/_A6A9800.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	color: white;
	min-height: 100vh;
}
.trabajos h1,
.trabajos p {
	text-align: center;
	padding-top: 3rem;
}
.trabajos p {
	margin-bottom: 3rem;
	line-height: 2rem;
}

/* CONTACTO */
.contacto_page {
	background: url(../../src/Resources/pics/_A6A9744.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	color: white;
	min-height: 100vh;
}
.contacto_btn {
	background-color: white;
}

/* WHATSAPP LOGO */
.whatsapp_fixed {
	display: none;
}
.img_viewer > img {
	object-fit: cover !important;
	width: auto !important;
}
